import { render, staticRenderFns } from "./element-submenu-choice.vue?vue&type=template&id=71829e00&"
import script from "./element-submenu-choice.vue?vue&type=script&lang=ts&"
export * from "./element-submenu-choice.vue?vue&type=script&lang=ts&"
import style0 from "./element-submenu-choice.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VFlex,VHover,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1872305353/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71829e00')) {
      api.createRecord('71829e00', component.options)
    } else {
      api.reload('71829e00', component.options)
    }
    module.hot.accept("./element-submenu-choice.vue?vue&type=template&id=71829e00&", function () {
      api.rerender('71829e00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/elements/element-submenu-choice.vue"
export default component.exports