import { render, staticRenderFns } from "./comment-reactions.vue?vue&type=template&id=20ea352e&scoped=true&"
import script from "./comment-reactions.vue?vue&type=script&lang=ts&"
export * from "./comment-reactions.vue?vue&type=script&lang=ts&"
import style0 from "./comment-reactions.vue?vue&type=style&index=0&id=20ea352e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ea352e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VChip,VContainer,VDivider,VIcon,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1872305353/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20ea352e')) {
      api.createRecord('20ea352e', component.options)
    } else {
      api.reload('20ea352e', component.options)
    }
    module.hot.accept("./comment-reactions.vue?vue&type=template&id=20ea352e&scoped=true&", function () {
      api.rerender('20ea352e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/comments/comment-reactions.vue"
export default component.exports