export default {
  header_title: 'Capacitación',
  recommended: {
    title: 'Recomendación:',
    subtitle: 'En función de tu chequeo actual, te recomendamos los siguientes módulos:',
  },
  modules: {
    title: 'Módulos',
    sessions: 'Sesion de Capacitación',
    button_explore: 'Explorar',
    button_start_session: 'Iniciar sesión',
  },
  discover: {
    title: 'Descubrir:',
  },
  completed: {
    title: 'Completado:',
    subtitle: 'Revisa los módulos que has completado para practicar tus habilidades.',
  },
  first_checkup: {
    text: 'Completa un <strong>Chequeo</strong> para generar tu recomendación.',
    button: 'Hacer un Chequeo',
  },
  buttons: {
    more: 'Ver más',
    less: 'Ver menos',
  },
  empty: {
    title: '',
    subtitle: '',
  },
  element: {
    reports: {
      download_button: 'Descargar datos',
    },
    popup: {
      tell_more_button: 'Cuéntame más.',
      download_button: 'Descargar documento PDF',
    },
    goal: {
      title: 'Objetivo creado',
      subtitle: '¡Buen trabajo estableciendo tu objetivo! Trabajemos en los próximos días para alcanzarlo. <br><br> Esta es tu tarjeta de objetivos. Puedes encontrarla en el historial de tu establecedor de objetivos.',
    },
    mood: {
      title: 'Estado de ánimo monitoreado',
      subtitle: '¡Esta es tu tarjeta de estados de ánimo! Puedes encontrarla en el historial de tu monitor de estado de ánimo.',
    },
    thought: {
      title: 'Pensamiento equilibrado',
      subtitle: '¡Buen trabajo equilibrando tu pensamiento! Puede ser muy difícil pensar de otra manera.<br><br>¡Esta es tu tarjeta de pensamiento! Puedes encontrarla en el historial de tu equilibrador de pensamientos.',
    },
    cu_scheduler: {
      label: 'Hacer mi próximo chequeo en',
      choices: {
        two_weeks: '2 semanas',
        four_weeks: '4 semanas',
        six_weeks: '6 semanas',
        eight_weeks: '8 semanas',
      },
    },
    webinar_registration: {
      register_button: 'Haz clic para registrarse',
      unregister_button: '¡Registrado!',
      success_message: 'Revisa tu correo electrónico para la confirmación de registro.',
      join_now_button: 'En vivo, haz clic para participar!',
      removed_message: 'Registro eliminado. Puedes registrarte de nuevo en cualquier momento si cambias de opinión.',
      default_message: 'Confirmamos tu registro en este seminario web. ¡Nos vemos allí!',
      error_message: 'Se ha producido un error al procesar tu solicitud. Ponte en contacto con <a target="_blank" href="mailto:members@starlingminds.com?subject=Webinar%20registration%20issue">members@starlingminds.com</a> para obtener ayuda.',
      confirmation: {
        title: '¿Suprimir el registro?',
        description: 'Ya no recibirás comunicaciones sobre este seminario web.',
      },
      notifications: {
        title: 'Atención',
        description: 'Al inscribirte a este seminario web, se activarán en tu <strong>perfil</strong> las notificaciones por correo electrónico y las notificaciones automáticas para los seminarios web.',
        warning: 'Al desactivar las notificaciones, dejarás de recibir actualizaciones sobre los seminarios web a los que hayas decidido inscribirte.<br/><br/> Para cancelar la inscripción a un seminario web, dirígete a la página de inscripción a los <strong>seminarios web</strong> en tu <strong>perfil</strong>.',
      },
    },
  },
  comments: {
    buttons: {
      post: 'Publicar',
    },
    empty: {
      title: 'Todavía no hay comentarios. Comparte tus pensamientos y escribe el primero.',
    },
    author: '{name} (yo)',
    reply: { label: 'Agrega tu respuesta' },
    comment: { label: 'Compartir un comentario con la comunidad' },
    comment_count: 'Comentario | Comentarios',
    comment_card: {
      actions: {
        guideline: {
          label: 'Guía',
          icon: 'help_outline',
          showSameUser: true,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
        hide: {
          label: 'Ocultar',
          icon: 'visibility_off',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
        unhide: {
          label: 'Mostrar',
          icon: 'visibility',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: true,
        },
        block: {
          label: 'Bloquear',
          icon: 'block',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        delete: {
          label: 'Borrar',
          icon: 'delete',
          showSameUser: true,
          showOtherUser: false,
          showModerator: false,
          showHidden: false,
        },
        edit: {
          label: 'Editar',
          icon: 'edit',
          showSameUser: true,
          showOtherUser: false,
          showModerator: false,
          showHidden: false,
        },
        report: {
          label: 'Denunciar',
          icon: 'report',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        reported: {
          label: 'Denunciado',
          icon: 'check_circle',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        translate: {
          label: 'Traducir',
          icon: 'translate',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
      },
      from: 'Desde',
      preview: 'previsualizar esta pantalla',
      translated: 'Esta publicación ha sido traducida de su idioma original.',
      translated_cancel: 'Mostrar la publicación en su idioma original',
      first_respond: 'Sé la primera persona en responder',
      reply: 'respuesta',
      replies: 'respuestas',
      helpful: 'Útil',
      care: 'me importa',
      thanks: 'gracias',
      report: {
        title: 'Denunciar comentario',
        subtitle: 'Gracias por denunciar este comentario. Indícanos el motivo de la denuncia.',
        reasons: [
          'Contiene lenguaje ofensivo, representa acoso o abuso.',
          'Es spam o está fuera de tema.',
          'Indica potencial de autolesión o pensamientos suicidas.',
        ],
      },
      guideline: {
        title: 'Guía de la comunidad',
        paragraph_first: 'Conéctate con la comunidad para compartir tu experiencia y leer los comentarios de otros miembros.',
        subtitle_first: '<strong>Nuestras normas de conducta</strong>',
        rules: [
          'Respeta a los demás.',
          'No compartas información que permita identificarte.',
          'Apoya a los demás.',
        ],
        paragraph_second: 'Nuestro equipo de asistencia monitorea las publicaciones para asegurarse de que este sea un espacio seguro para cada persona. Sin embargo, puedes ocultar, bloquear y denunciar los comentarios de otros miembros, de modo que tú decides lo que quieres ver.',
        subtitle_second: '<strong>Responder a un comentario</strong>',
        paragraph_third: 'Responde y añade reacciones a los comentarios de otros miembros.',
        helpful: '<strong>Útil</strong> - Muestra tu agradecimiento por un comentario relevante.',
        care: '<strong>Me importa</strong> - Muestra tu apoyo a otro miembro.',
        thanks: '<strong>Gracias</strong> - Muestra tu agradecimiento a otro miembro.',
        button_action: '¡Entiendo!',
      },
      hide_confirmation: {
        title: 'Ocultar publicación',
        subtitle: 'Esta publicación quedará oculta para ti.',
      },
      block_confirmation: {
        title: 'Bloquear miembro',
        subtitle: 'Ya no verás ninguna publicación o respuesta de este miembro.',
      },
    },
    sorts: [ 'Ordenar por más reciente', 'Ordenar por más antiguo', 'Ordenar por número de “reacciones”' ],
    notifications: {
      reported: 'Comentario denunciado',
      updated: 'Comentario actualizado',
      deleted: 'Comentario borrado',
      helpful_marked: 'Comentario marcado “me gusta”',
      helpful_unmarked: 'Comentario con marca “me gusta” retirada',
      care_marked: 'Comentario marcado “me importa”',
      care_unmarked: 'Comentario con marca “me importa” retirada',
      thanks_marked: 'Comentario marcado “gracias”',
      thanks_unmarked: 'Comentario con marca “gracias” retirada',
      replied: 'Respuesta añadida',
      commented: 'Comentario añadido',
    },
  },
  element_types: {
    video: {
      title: 'Video',
      icon: 'mdi-play-box-outline',
      color: '#5CCEFF',
    },
    workbook: {
      title: 'Workbook',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Your Response',
      color: '#C288CB',
    },
    poll: {
      title: 'Poll',
      icon: 'mdi-poll-box-outline',
      color: '#75CB99',
    },
    decision: {
      title: 'Decision',
      icon: 'mdi-arrow-decision',
      color: '#FFA4A9',
      subtitle: 'Choose what you would like to do next',
    },
    submenu: {
      title: 'Submenu',
      icon: 'mdi-menu-open',
      color: '#0098E1',
      subtitle: 'Choose what you would like to do next',
    },
    mood: {
      title: 'Mood Tracker',
      icon: 'mdi-emoticon-confused',
      color: '#FFB92E',
    },
    html: {
      title: 'HTML',
      icon: 'mdi-note',
      color: '#6A89FF',
    },
    goal: {
      title: 'Goal Setter',
      icon: 'mdi-mountain',
      color: '#6CC5B9',
    },
    assessment: {
      title: 'Assessment',
      icon: 'mdi-head-cog',
      color: '#FF8884',
      instructions: 'Responde a todas las preguntas para continuar.',
    },
    thoughts: {
      title: 'Thought Balancer',
      icon: 'mdi-weather-lightning-rainy',
      color: '#E1CCF1',
    },
    mcq: {
      title: 'MCQ',
      icon: 'mdi-format-list-bulleted',
      color: '#E1CCF1',
    },
    msq: {
      title: 'MSQ',
      icon: 'mdi-format-list-bulleted-square',
      color: '#F2DDA2',
    },
    slider: {
      title: 'Slider',
      icon: 'mdi-gauge',
      color: '#ff9800',
    },
    composition: {
      title: 'Composition',
      icon: 'mdi-animation-outline',
      color: '#7E8DC1',
    },
    next_step: {
      title: 'Next Step',
      icon: 'mdi-page-next',
      color: '#736010',
    },
    popup: {
      title: 'Popup',
      icon: 'mdi-launch',
      color: '#CABE70',
    },
    cu_scheduler: {
      title: 'Check-Up Scheduler',
      icon: 'mdi-calendar',
      color: '#AEDD2F',
    },
    journal: {
      title: 'Journal',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Add yout thoughts',
      color: '#C288CB',
    },
    accordion: {
      title: 'Accordion',
      icon: 'mdi-chevron-down',
      color: '#B4BC54',
    },
    carousel: {
      title: 'Carousel',
      icon: 'mdi-view-carousel',
      color: '#47688C',
    },
    note: {
      title: 'Note',
      icon: 'mdi-book-open-page-variant',
      color: '#C288CB',
    },
  },
  assessment_history_chart: {
    title: 'Historial de evaluación',
    chart: {
      label: {
        xAxis: 'Fechas',
        xAxisVersion: 'final',
        yAxis: 'Puntuación',
      },
      score: {
        label: {
          severe: 'Grave',
          moderate: 'Moderado',
          mild: 'Leve',
          border_line: 'Límite',
          healthy_low: 'Saludable: Medio bajo',
          healthy_average: 'Saludable: Medio',
          helthy_high: 'Saludable: Medio alto',
        },
      },
      assessment: {
        label: {
          gad7: 'ANSIEDAD',
          phq9: 'DEPRESIÓN',
          stress: 'ESTRÉS',
          energy: 'ENERGÍA',
          'cd-risc2': 'RESILIENCIA',
          ldq: 'LDQ',
        },
      },
    },
  },
  parts: {
    guide: {
      read_more: 'Leer más',
      read_less: 'Leer menos',
    },
    navigation: {
      button: 'Regístrese',
    },
    step_number: {
      title: 'Paso {step} de {of}',
    },
  },
};
