import { render, staticRenderFns } from "./notification-bar.vue?vue&type=template&id=16b31e05&"
import script from "./notification-bar.vue?vue&type=script&lang=js&"
export * from "./notification-bar.vue?vue&type=script&lang=js&"
import style0 from "./notification-bar.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VIcon,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1872305353/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16b31e05')) {
      api.createRecord('16b31e05', component.options)
    } else {
      api.reload('16b31e05', component.options)
    }
    module.hot.accept("./notification-bar.vue?vue&type=template&id=16b31e05&", function () {
      api.rerender('16b31e05', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/utilities/notification-bar.vue"
export default component.exports