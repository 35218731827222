import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { api } from '../api';
import { assessment } from './modules/assessments/index';
import { comments } from './modules/comments/index';
import { home } from './modules/home/index';
import { tools } from './modules/tools/index';
import { goals } from './modules/tools/goals/index';
import { journal } from './modules/journal/index';
import { moods } from './modules/tools/moods/index';
import { therapy } from './modules/therapy/index';
import { thoughts } from './modules/tools/thoughts/index';
import { identity } from './modules/identity/index';
import { accessCode } from './modules/access_codes/index';
import { favorites } from './modules/favorites/index';
import { journals } from './modules/tools/journals/index';
import { notes } from './modules/tools/notes/index';
import { navigation } from './modules/navigation/index';
import { webinar } from './modules/webinar/index';
import auth from './modules/auth';
import you from './modules/you';
import ping from './modules/ping';
import content from './modules/content';
import { locale, setLanguage } from '@/scripts/lang';
import { enUS, es, fr } from 'date-fns/locale';

Vue.use(Vuex);

const initialState: RootState = {
  contentVersion: '1.0.0',
  platform: 'web',
  maintenance: undefined,
  notification: undefined,
  loadingSpinner: false,
  loadingView: false,
  loadingProgress: undefined,
  actionSpinner: false,
  undoDelete: false,
  routerContext: {
    path: false,
    label: false,
  },
  isAuthorized: false,
  currentLanguage: localStorage.getItem('currLang') || locale,
  languages: [
    { name: 'en', locale: enUS, hourFormat: 'ampm', abbreviatedDate: 'EEE, MMM d, y' },
    { name: 'es', locale: es, hourFormat: '24hr', abbreviatedDate: 'EEE, d \'de\' MMM \'de\' y' },
    { name: 'fr', locale: fr, hourFormat: '24hr', abbreviatedDate: 'EEE d MMM y' },
  ],
  regulationTools: undefined,
  jiraScript: undefined,
  afterLoginRoute: undefined,
  debugEnabled: false,
};

const $store: StoreOptions<RootState> = {
  modules: {
    assessment,
    comments,
    home,
    tools,
    goals,
    journal,
    moods,
    therapy,
    thoughts,
    auth,
    identity,
    you,
    favorites,
    journals,
    navigation,
    content,
    ping,
    accessCode,
    notes,
    webinar,
  },
  // ROOT STATE
  state: {
    ...initialState,
  },
  // ROOT MUTATIONS
  mutations: {
    setContentVersion(state, contentVersion) {
      state.contentVersion = contentVersion;
    },
    setPlatform(state, platform) {
      state.platform = platform;
    },
    resetIndexState(state) {
      Object.assign(state, initialState);
    },
    routerContext(state, routerData) {
      state.routerContext = routerData;
    },
    resetRouterContext(state) {
      state.routerContext = {
        path: false,
        label: false,
      };
    },
    setIsAuthorized(state, isAuth) {
      state.isAuthorized = isAuth;
    },
    setMaintenance(state, maintenance) {
      state.maintenance = maintenance;
    },
    setNotification(state, notification) {
      const defaultNotification = { timeout: 5000, color: 'info' };
      state.notification = Object.assign(defaultNotification, notification);
    },
    setUndoDelete(state, undoDelete) {
      state.undoDelete = undoDelete;
    },
    setLoadingSpinner(state, isLoading) {
      state.loadingSpinner = isLoading;
    },
    setActionSpinner(state, isLoading) {
      state.actionSpinner = isLoading;
    },
    setLoadingView(state, isLoading) {
      state.loadingView = isLoading;
    },
    setLoadingProgress(state, loadingProgress) {
      if (loadingProgress) {
        if (state.loadingProgress === undefined && loadingProgress > 100) return;
        if (!!state.loadingProgress && state.loadingProgress >= loadingProgress) return;
      }
      state.loadingProgress = loadingProgress;
    },
    removeNotification(state) {
      state.notification = undefined;
    },
    setLanguage(state, language) {
      state.currentLanguage = language;
    },
    setAfterLoginRoute(state, afterLoginRoute) {
      state.afterLoginRoute = afterLoginRoute;
    },
    setDebugEnabled(state, debugEnabled) {
      state.debugEnabled = debugEnabled;
    },
  },
  // ROOT ACTIONS TO COMMIT MUTATIONS & API HELPERS
  actions: {
    ...api,
    setRouterContext({ commit }, routerData) {
      commit('routerContext', routerData);
    },
    setAppLocale({ commit }, language) {
      setLanguage(language);
      commit('setLanguage', language);
    },
    resetState({ commit }) {
      commit('resetAuthState');
      commit('assessment/resetAssessmentState');
      commit('goals/resetGoalsState');
      commit('moods/resetMoodsState');
      commit('thoughts/resetThoughtsState');
      commit('resetYouState');
      commit('home/resetProductState');
      commit('resetIndexState');
      commit('journals/resetJournalsState');
      commit('notes/resetNotesState');
    },
  },
  getters: {
    getContentVersion(state) {
      return state.contentVersion;
    },
    getPlatform(state) {
      return state.platform;
    },
    isNativePlatform(state) {
      return state.platform === 'ios' || state.platform === 'android';
    },
    isLoadingProgress(state) {
      return state.loadingProgress !== undefined && state.loadingProgress < 999;
    },
    getLanguages(state) {
      return state.languages;
    },
    isAuthorized(state) {
      return state.isAuthorized;
    },
    currentLanguage(state) {
      return state.currentLanguage;
    },
    getCurrentLocale(state) {
      return state.languages.find(lang => lang.name === state.currentLanguage)?.locale;
    },
    getAbbreviatedDateFormat(state) {
      return state.languages.find(lang => lang.name === state.currentLanguage)?.abbreviatedDate;
    },
    getHourFormat(state) {
      return state.languages.find(lang => lang.name === state.currentLanguage)?.hourFormat;
    },
    regulationTools(state) {
      return state.regulationTools;
    },
    notification(state) {
      return state.notification;
    },
    maintenance(state) {
      return state.maintenance;
    },
    undoDelete(state) {
      return state.undoDelete;
    },
    afterLoginRoute(state) {
      return state.afterLoginRoute;
    },
    debugEnabled(state) {
      return state.debugEnabled;
    },
  },
};

export default new Vuex.Store<RootState>($store);
