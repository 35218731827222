import { render, staticRenderFns } from "./element-workbook.vue?vue&type=template&id=3c6235aa&"
import script from "./element-workbook.vue?vue&type=script&lang=ts&"
export * from "./element-workbook.vue?vue&type=script&lang=ts&"
import style0 from "./element-workbook.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VContainer,VFlex,VForm,VLayout,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1872305353/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c6235aa')) {
      api.createRecord('3c6235aa', component.options)
    } else {
      api.reload('3c6235aa', component.options)
    }
    module.hot.accept("./element-workbook.vue?vue&type=template&id=3c6235aa&", function () {
      api.rerender('3c6235aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/elements/element-workbook.vue"
export default component.exports